@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* only custom ccs without tailwind */
html,
body,
#root,
.app {
  @apply text-white;
  font-family: "Quicksand", sans-serif;
}
.linux {
  filter: saturate(50%);
}
